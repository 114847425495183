<script>

/*
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
*/
  import documentManager from "@/components/widgets/documentManager";
 

  import useVuelidate from "@vuelidate/core";

  import Layout from "../../../layouts/main.vue";
  import appConfig from "../../../../app.config";
  import PageHeader from "@/components/page-header";

  import serverConfig   from '@/helpers/config';

  import Swal from "sweetalert2";
  import axios from 'axios';
  import moment from 'moment';
  import brandSearchForm from "@/components/widgets/brandSearchForm";
  
  import salesRouteSearchForm from "@/components/widgets/salesRouteSearchForm";
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { mapActions, mapGetters} from "vuex";

  export default {
    page: {
      title: "Modifica prodotto",
      meta: [{
        name: "description",
        content: appConfig.description,
      }, ],
    },
    setup() {
      moment.locale('it')
      const store = useStore();
 
      let tk = 'Bearer '+localStorage.getItem('tk')
      let brands = computed(() => store.getters["ecommercesettings/getBrands"])


      return {
        v$: useVuelidate(),
        tk,
        brands
      };
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        user:this.$store.state.auth,
        title: "Modifica prodotto",
        min_date: moment().toISOString(),
        optionsStatus:[
          { value: 'Published', label: 'Pubblicato' },
          { value: 'Scheduled', label: 'Schedulato' },
          { value: 'Draft', label: 'Bozza' },
        ],
        optionsVisibility:[
          { value: 'Public', label: 'Pubblico' },
          { value: 'Hidden', label: 'Nascosto' },
        ],
        item:{
/**
 *      { "low_availability": { "choice": "NO" }, 
 *      "on_demand": { "choice": "NO" }, 
 *      "position": 0, 
 *      "giacenzaEffettiva": 0, 
 *      "size": "", "colors": [], 
 *      "status": "init", 
 *      "packagingLength": 0, 
 *       "packagingWidth": 0, 
 *       "packagingHeight": 0, "packagingWeight": 0, "packagingThickness": 0, "tokenizer": [], 
 *       "_id": "64abe23d0eb6500ba5518a22", 
 *       "Listino": "01", 
 *       "Famiglia": "Split", 
 *       "Tipologia": "Componenti", 
 *        "Refrigerante": "R32", 
 *       "GruppoSconto": "Residenziale", 
 *       "gcode": "FTXA20AW", 
 *         "ean": "4548848657117", 
 *       "CodMerc": "84159000", 
 *       "Descrizione": "Stylish R32 interna white 20", 
 *       "FamECR": "", 
 *       "Parametro": "", "ECR": "", 
 *        "Febbraio 2023": "510.02", 
 *        "Stato": "", "Lordo": "13.50", " Netto": "11.50", 
 *         "um": "KG", "Lunghezza": 0.88, "Larghezza": 0.4, "Altezza": 0.3, "Unità": "M", "Volume": 0.11, "UV": "M3", "Volume_2": 106, "UV_2": "DM3", "TipoApparecchiature": "", "TipologiaInst": "", "Circuiti": "", "TipologiaFGAS": "", "QtaKg": "", "createdDate": "2023-07-10T13:52:08.116Z", "id": "64abe23d0eb6500ba5518a22" }
 */    
          um:'',
          Famiglia:'',
          Tipologia:'',
          Refrigerante:'',
          GruppoSconto:'',
          gcode:'',
          ean:'',
          CodMerc:'',
          Nome:'',
          slug:'',
          Descrizione:'',
          FamECR:'',
          Parametro:'',
          ECR:'',
          Lunghezza:0.00,
          Larghezza:0.00,
          Altezza:0.00,
          Unita:'',
          Volume:0.00,
          UV:'',
          Volume_2:0.00,
          UV_2:'',
          TipoApparecchiature:'',
          TipologiaInst:'',
          Circuiti:'',
          TipologiaFGAS:'',
          QtaKg:0.00,
          CodFamiglia: '',
          CodMarca: '',
          publishSchedule: moment.unix(),
          publishScheduleISO: moment().toISOString(),
        },
        retailPrice:false,
        items: [
          {
            text: "Catalogo",
            href: "/ecommerce/products",
          },
          {
            text: "Modifica prodotto",
            active: true,
          },
        ],
        date1: null,
        datetimeConfig: {
          enableTime: true,
          dateFormat: "DD/MM/YYYY",
          time_24hr: true,
        },
        value1: ["Scheduled"],
        value2: ["Hidden"],
        value3: ["Fashion"],
        editor: '',
        editorData: "",
        content: "<h1>Some initial content</h1>",
        categoriesList:[],
        optionsCat:[],
        optionsSubCat:[],
        optionsSubCatFiltered:[],
        salesRouteUser:{},
        compID:false,
        imgCoverPath:false,
      };
    },
    components: {
      Layout,
      PageHeader,
      brandSearchForm,
      salesRouteSearchForm,
      documentManager,
     
      //Multiselect,
    },
    computed:{
      ...mapGetters('ecommercesettings', ['getBrands']),
    },
    mounted() {
      if(this.user.currentUser){
        if (this.user.currentUser.id){
          this.getSalesRouteList(this.user.currentUser.id)
        }
      }

      this.getItem()
      //this.getCategories()
    },
    methods: {
      ...mapActions('ecommercesettings', ['getBrands']),
      ...mapActions('layout', ['changeLayoutType', ]),
      axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                this.$router.push('/')          
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
      },
      getParent(){
       this.getItem()
      },
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.',',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      deleteRecord(ele) {
        ele.target.parentElement.parentElement.remove();
      },
      slugify()
      { 
        this.item.slug = this.item.Nome.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'').trim()
        this.updateItem()
      },
      setDate(){
        this.item.publishSchedule = moment(this.item.publishScheduleISO).unix()
      },
      getSalesRouteList(id){
        let tk = localStorage.getItem('tk')
        
        axios.get(`${this.UrlServer}sales-route/srlcompany/${id}`,  
            {headers: { authorization:`Bearer ${tk}`}} 
          ).then((response)=>{
            if (response.data){
              this.salesRouteUser = response.data[0]
              this.compID = response.data[0].companyID
            }
            
          })          
      },  
      discCalcBase(price, discount){ 
        if (discount>0) {
          return this.formatPrice(price - ((price*discount)/100))
        }
      },
      discCalcFam(price, discount, discount2){
        if (discount2>0) {
          let price_1 = price - ((price*discount)/100)
          return this.formatPrice(price_1 - ((price_1*discount2)/100) )
        }
      },      
      getItem() {
      this.axiosInterceptor()
      this.data = {}
        axios.get(`${this.UrlServer}products/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data && response.data.length > 0) {
             console.log(this.compID)
            this.item = response.data[0]
            if (!response.data[0].Nome){
              this.item.Nome = response.data[0].Descrizione.slice(0,50)
            }
            this.editorData = response.data[0].Descrizione
            if(response.data[0].image){
              if (response.data[0].image!=''){
             
                this.getPathList(response.data[0].image)
              }
            }
            let self = this
            setTimeout(()=>{
                
                self.getRetailPrice(self.item.CodiceGruppo, self.salesRouteUser.companyID)           
            },2000)
          
           

          }                  
      }).catch((error) => {
              if (error == "Error: Request failed with status code 401")
              {
                console.log(error)
              } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }
      });
    },
          
    getRetailPrice(codiceGruppo, compID) {
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}sales-route/search/retail-price/list`,  { 
          params: { 
            codiceGruppo: codiceGruppo,
            companyID: compID,
            
          }, 
          headers: { 'authorization':this.tk}
         }).then((response) => {
          if (response.data) {
            this.retailPrice = response.data
          }                  
      })
    },
    getPathList(data) {
      let l = [{path:data}]
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}storage/db/by/file-path-list`, 
        { 
          params: { 
            fileList: l,
          }, 
          headers: { 'authorization':this.tk}
        }
      ).then(response=>{
          if (response.data.length>0){
            this.imgCoverPath  = response.data[0].url
          }
          
        }).catch((error) => {
            if (error == "Error: Request failed with status code 401")
            {
              // this.alertSessionExp()
            } else {
              Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }

        });
      },
    getCategories() {
      axios.get(`${this.UrlServer}categories/list`, { headers: { authorization:this.tk } }).then((response) => {
          if (response.data) {
            this.categoriesList = response.data
            this.setOptions('categories', this.categoriesList)
          }                  
      }).catch((error) => {
        if (error.message == "Request failed with status code 404")
        {
          Swal.fire( this.$t("t-msg-dealer-price-404") , this.$t("t-msg-sorry-no-dealer-price"), "warning");

        } else {
          Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
        }
      });
    },
    setOptions(type,listv){
      if (type=='categories'){
       for (let item of listv){
        if (!item.type_doc){
           if (item.type_doc=='child'){
            this.optionsSubCat.push(item)
           }
        } else {
           this.optionsCat.push(item)
        }
       }
      }
    },
    updateItem() {
      this.axiosInterceptor()
        axios.put(`${this.UrlServer}products/${this.$route.params.id}`,this.item, {headers: { authorization:this.tk}}).then((response) => {
        console.log(response.data)               
      }).catch((error) => {
              if (error.message == "Request failed with status code 404")
              {
                console.log(error)
              } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }
      });
    },
    updateRetailPrice(id, item) {
      this.axiosInterceptor()
        axios.put(`${this.UrlServer}products/retail-price/${id}`,item, {headers: { authorization:this.tk}}).then((response) => {
          console.log("response.data")
          console.log(response.data)
          this.getItem()            
      }).catch((error) => {
        if (error.message == "Request failed with status code 404")
        {
          console.log(error)
        } else {
          Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
        }
      });
    },
    
    createRetailPrice() {
      if (this.retailPrice.Prezzo>0){
        this.retailPrice.CodArt = this.item.gcode
        this.retailPrice.ean = this.item.ean
        this.retailPrice.CodMerc = this.item.CodMerc
        this.retailPrice.Famiglia = this.item.Famiglia
        this.retailPrice.GruppoSconto = this.item.GruppoSconto

        axios.post(`${this.UrlServer}products/retail-price/create`,this.retailPrice, {headers: { authorization:this.tk}}).then((response) => {
          console.log(response.data)
          this.getItem()            
        }).catch((error) => {
          if (error.message == "Request failed with status code 404")
          {
            console.log(error)
          } else {
            Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
          }
        });
      }
    },
    changeCat(){
      let self = this
      if (this.item.Famiglia!=''){
        let famiglia = this.categoriesList.filter(function(el){
          return el.code == self.item.Famiglia
        })
        if (famiglia){
         
            console.log(famiglia[0]._id)
            for (let item of this.optionsSubCat) {
              console.log(item._id)
            }
          
        }
      }

    }


    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-8">
        <form>
          <div class="card">
           
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label" for="product-title-input">{{ $t("t-product-name") }}</label>
                <input type="text" class="form-control" id="product-title-input" placeholder="Inserisci nome prodotto" v-model="item.Nome" @blur="slugify(),updateItem"/>
              </div>
              <div class="mb-3">
                <label class="form-label" for="product-title-input">{{ $t("t-slug-name") }}</label>
                <input type="text" class="form-control" id="product-slug-input" placeholder="Slug nome prodotto" v-model="item.slug" @blur="updateItem" />
              </div>
              <div class="mb-3">
                <label class="form-label" for="product-title-input">URL Immagine di copertina</label>
                <input type="text" class="form-control" id="product-slug-input" placeholder="Slug nome prodotto" v-model="item.image" @blur="updateItem" />
              </div>
              
              <div class="mb-3" v-if="imgCoverPath!=false">
                <label class="form-label" for="product-title-input">Immagine di copertina</label><br>
                  <b-img :src="imgCoverPath" class="img-fluid" alt="Immagine di copertina"> </b-img> 
              </div>
              <div>
                <label>{{ $t("t-product-description") }}</label>
               
                <quill-editor v-model="item.Descrizione" @blur="updateItem" ></quill-editor>

              </div>
            </div>
          </div>
          <!-- end card -->



          <div class="card">
            <div class="card-header">
              <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" data-bs-toggle="tab" href="#addproduct-general-info" role="tab">
                    Informazioni Generali
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-bs-toggle="tab" href="#addproduct-metadata" role="tab">
                    Meta Dati
                  </a>
                </li>
              </ul>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content">
                <div class="tab-pane active" id="addproduct-general-info" role="tabpanel">
                  <div class="row">
                    <div class="col-lg-4 col-md-12">
                      <div class="mb-3 ml-20 pl-20 pt-10 ">                         
                          <label for="marca" class="form-label">Marca</label>
                          <div class="">
                            <template v-if="item.marca && item.marca !='' ">
                              <h3> {{ item.marca }}</h3>
                            </template>
                            <template v-else>
                              Marca non indicata
                            </template>
                          </div>
                          <div class="mb-50">
                            <brandSearchForm :ID="item._id" type="product"  :urlPut="`${this.UrlServer}products/${item._id}`" ></brandSearchForm>
                          </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 background-btn-grey-1 mb-50">
                      <div class="mb-3 ml-20 pl-20 pt-10 ">                         
                          <label for="marca" class="form-label">Famiglia Gruppo Sconto</label>
                          <div class="">
                            <template v-if="item.nomeGruppoSconto && item.nomeGruppoSconto !='' ">
                              <h3 class="mb-20"> {{ item.nomeGruppoSconto }}</h3>
                            </template>
                            <template v-else>
                              <span  class="mb-20">Gruppo sconto non indicato</span>
                            </template>
                          </div>
                          <div class="mb-50 mt-20">
                            <salesRouteSearchForm :ID="item._id" type="product" :brandCode="item.CodMarca" :urlPut="`${this.UrlServer}products/${item._id}`" ></salesRouteSearchForm>
                          </div>
                        </div>
                    </div>
                    
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="mb-3">
                            <label class="form-label" for="product-gcode-input">Codice generico</label>
                            <div class="input-group mb-3">
                              <span class="input-group-text" id="product-gcode-addon">G</span>
                              <input type="text" class="form-control" id="product-gcode-input" placeholder="Inserisci codice generico"
                                aria-label="gcode" aria-describedby="product-gcode-addon" v-model="item.gcode" @blur="updateItem" />
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="mb-3">
                            <label class="form-label" for="product-ean-input">Codice EAN</label>
                            <div class="input-group mb-3">
                              <span class="input-group-text" id="product-ean-addon">EAN</span>
                              <input type="text" class="form-control" id="product-ean-input"
                                placeholder="Inserisci EAN" aria-label="ean"
                                aria-describedby="product-ean-addon"  v-model="item.ean" @blur="updateItem"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="row mb-50">                    
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="mb-50 mt-50">
                            <label class="form-label" for="product-price-input">Prezzo di listino</label>
                            <div class="input-group mb-3">
                              <template v-if="item.price_list && item.price_list.length > 0">
                                <span class="input-group-text" id="product-price-addon">&euro;  <strong class="pl-5">{{ formatPrice(item.price_list[0].Prezzo) }}</strong></span>
                              </template>
                              <!--<template v-if="item.price_list && item.price_list.length > 0">
                                <input type="number" v-model="item.price_list[0].Prezzo" class="form-control" id="product-price-input" placeholder="Inserisci prezzo"
                                  aria-label="Prezzo di listino" aria-describedby="product-price-addon" @blur="updateRetailPrice(item.price_list[0]._id, item.price_list[0])" />
                              </template>
                              <template v-else>
                                <input type="number" v-model="retailPrice.Prezzo" class="form-control" id="product-price-input" placeholder="Inserisci prezzo"
                                aria-label="Prezzo di listino" aria-describedby="product-price-addon" @blur="createRetailPrice" />
                              </template>-->
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-50 mt-50">
                            <label class="form-label" for="product-price-input">Prezzo con sconto base</label>
                            <div class="input-group mb-3">
                              <template v-if="retailPrice">
                                <span class="input-group-text" id="product-price-addon"> (- {{retailPrice.scontoPercBase}}%) &euro;  <strong class="pl-5" v-if="item.price_list.length>0">{{ discCalcBase(item.price_list[0].Prezzo, retailPrice.scontoPercBase) }}</strong></span>
                              </template>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="mb-50 mt-50">
                            <label class="form-label" for="product-price-input">Prezzo con sconto famiglia</label>
                            <div class="input-group mb-3">
                              <template v-if="retailPrice">
                                <span class="input-group-text" id="product-price-addon"> (- {{retailPrice.scontoPercAggiuntivo}}%) &euro;  <strong class="pl-5" v-if="item.price_list.length>0">{{ discCalcFam(item.price_list[0].Prezzo, retailPrice.scontoPercBase, retailPrice.scontoPercAggiuntivo) }}</strong></span>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <!-- end row -->
                </div>
                <!-- end tab-pane -->

                <div class="tab-pane" id="addproduct-metadata" role="tabpanel">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-title-input">Meta titolo</label>
                        <input type="text" class="form-control" placeholder="Inserisci meta titolo" id="meta-title-input" />
                      </div>
                    </div>
                    <!-- end col -->

                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label" for="meta-keywords-input">Meta Keywords</label>
                        <input type="text" class="form-control" placeholder="Inserisci meta keywords"
                          id="meta-keywords-input" />
                      </div>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->

                  <div>
                    <label class="form-label" for="meta-description-input">Meta Descrizione</label>
                    <textarea class="form-control" id="meta-description-input" placeholder="Inserisci meta descrizione"
                      rows="3"></textarea>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->



            
          </div>
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Galleria immagini e allegati</h5>
            </div>
            <div class="card-body">
            <!--
                :projectID="data._id" 
                  :data="data" 
            -->
           
            <template v-if="item._id">
              <documentManager 
                  :productID="item._id"
                  :projectID="item._id"
                  :productCode="item.gcode"
                  :productEan="item.ean"
                  :url="`${this.UrlServer}storage/by/project`"
                  :data="item" 
                  bucket="store/images" 
                  type="product-gallery" 
                  fileType="multiple" 
                  fileAccept="*" 
                  title="Gestisci allegati"
                  v-on:getParent="getParent()"/>
            </template>

              
            </div>
          </div>
          <!-- end card -->
          <!-- end card -->
          <div class="text-end mb-3">
            
          </div>
        </form>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Stato pubblicazione</h5>
          </div>
          <div class="card-body">
            <div class="mb-3">
              <label for="choices-publish-status-input" class="form-label">Status</label>

              <select class="form-control" v-model="item.status" @change="updateItem">
                <option v-for="item in optionsStatus" :key="item" :value="item.value">
                  {{ item.label }}
                </option>
              </select>

            </div>

            <div>
              <label for="choices-publish-visibility-input" class="form-label">Visibilità</label>
              <select class="form-control" v-model="item.visibility" @change="updateItem">
                <option v-for="item in optionsVisibility" :key="item" :value="item.value">
                  {{ item.label }}
                </option>
              </select>

            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Pianifica pubblicazione</h5>
          </div>
          <!-- end card body -->
          <div class="card-body">
            <div>
              <label for="datepicker-publish-input" class="form-label">Data e orario pubblicazione</label>
                <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time"  :min="min_date"  v-model="item.publishScheduleISO" @change="setDate">
            </div>
          </div>
        </div>
        <!-- end card -->

        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Famiglia (Categoria)</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona famiglia
            </p>
            <select class="form-control" v-model="item.Famiglia" @change="changeCat()"  >
              <option v-for="item in optionsCat" :value="item.code" :key="item._id"> {{ item.name }} </option>
            </select>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Sotto Famiglia (Sotto-Categoria)</h5>
          </div>
          <div class="card-body">
            
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona famiglia 
            </p>
            <select class="form-control" v-model="item.sottoFamiglia"   >
              <option v-for="item in optionsSubCatFiltered"  :value="item.code" :key="item._id"> {{ item.name }}</option>
            </select>
          </div>
        </div>
      <!--  <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Tipologia prodotto</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona categoria prodotto
            </p>
            <Multiselect class="form-control" v-model="item.Tipologia" :close-on-select="true" :searchable="true"
              :create-option="true" :options="[
                { value: 'componenti', label: 'Componenti' },
                { value: 'set', label: 'Set' },
              
              ]" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Refrigerante</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuova</a>Seleziona Refrigerante
            </p>
            <Multiselect class="form-control" v-model="item.Refrigerante" :close-on-select="true" :searchable="true"
              :create-option="true" :options="[
                { value: '', label: 'Nessuno' },
                { value: 'R32', label: 'R32' },
              
              ]" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Gruppo Sconto</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">
              <a href="#" class="float-end text-decoration-underline">Aggiungi nuovo</a>Seleziona Gruppo Sconto
            </p>
            <Multiselect class="form-control" v-model="item.GruppoSconto" :close-on-select="true" :searchable="true"
              :create-option="true" :options="[
                { value: '', label: 'Nessuno' },
                { value: 'Accessori', label: 'R32' },
              
              ]" />
          </div>
        </div>-->
      <!--
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Tagga prodotto</h5>
          </div>
          <div class="card-body">
            <div class="hstack gap-3 align-items-start">
              <div class="flex-grow-1">
                <input class="form-control" data-choices data-choices-multiple-remove="true" placeholder="Enter tags"
                  type="text" value="Cotton"  />
              </div>
            </div>
          </div>
          
        </div>
    
        <div class="card">
          <div class="card-header">
            <h5 class="card-title mb-0">Product Short Description</h5>
          </div>
          <div class="card-body">
            <p class="text-muted mb-2">Add short description for product</p>
            <textarea class="form-control" placeholder="Must enter minimum of a 100 characters" rows="3"></textarea>
          </div>
          
        </div>
      -->
      </div>
    </div>
    
  </Layout>
</template>